


















import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';

@Component({
  components: {
    Button,
    GridCol,
    GridRow,
    MainHeader,
  },
})
export default class ProductsView extends Vue {
  /**
   * Data
   */
  private product: ProductCoreModel | null = null;

  /**
   * Getters
   */
  private get title(): string {
    return this.product ? this.product.name : 'Produkt';
  }

  private get productId(): number | null {
    return this.$route.params.id
      ? Number(this.$route.params.id)
      : null;
  }

  /**
   * Handlers
   */
  private onGetProduct(product: ProductCoreModel | null) {
    this.product = product;
  }
}
